<template>
  <ValidationObserver ref="observer" slim>
    <div class="row q-col-gutter-x-md q-pb-lg">
      <div class="col-24">
        <ProjectHeader :project="project" :viewers="viewers">
          <template v-slot:buttons>
            <q-btn
              v-if="+$can(['project.update'])"
              :disable="awaitSaving"
              :loading="awaitSaving"
              class="q-ml-md"
              color="positive"
              icon="mdi-content-save"
              label="Сохранить Общее"
              no-caps
              @click="save"
            />
          </template>
        </ProjectHeader>
      </div>

      <div class="col-24 col-md-8">
        <div
          class="l-card l-card--blue rounded-borders shadow-2 q-pa-md bg-white"
        >
          <form
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
          >
            <fieldset
              :disabled="awaitSaving"
              class="no-border q-pa-none q-ma-none"
            >
              <q-select
                v-model="project.biz_region"
                :options="$store.getters['auth/user'].biz_regions"
                bottom-slots
                label="Регион (бизнес)"
                map-options
                option-label="name"
                option-value="id"
                options-dense
              />

              <ValidationProvider
                v-slot="{ errors }"
                name="Название"
                rules="min:2|max:255"
              >
                <q-input
                  v-model="project.name"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  label="Название"
                />
              </ValidationProvider>

              <q-select
                v-model="project.project_status"
                :options="lists.projectStatuses"
                bottom-slots
                label="Статус"
                map-options
                option-label="name"
                option-value="id"
                options-dense
              />

              <q-select
                v-model="project.ap_project_serial"
                :options="apProjects"
                bottom-slots
                clearable
                emit-value
                label="АП проект"
                map-options
                option-label="name"
                option-value="serial"
                options-dense
                use-input
                @filter="filterApProjects"
              >
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                    <q-item-section>
                      <q-item-label>
                        <span>{{ scope.opt.name }}</span>
                        <span class="text-body2 text-grey-6"
                          >&nbsp id: {{ scope.opt.id }}</span
                        >
                        <span class="text-body2 text-grey-6"
                          >&nbsp регион: {{ scope.opt.region_name }}</span
                        >
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>

              <q-select
                v-model="project.project_kind"
                :options="lists.projectKinds"
                bottom-slots
                clearable
                label="Тип проекта"
                map-options
                option-label="name"
                option-value="id"
                options-dense
              />

              <q-select
                v-model="project.privacy_level"
                :options="lists.privacyLevels"
                bottom-slots
                clearable
                label="Уровень конфиденциальности"
                map-options
                option-label="name"
                option-value="id"
                options-dense
              />

              <q-select
                v-model="project.developers"
                :options="developers"
                bottom-slots
                filled
                label="Девелоперы"
                multiple
                option-label="name"
                option-value="id"
                options-dense
                use-input
                @filter="filterDevelopers"
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    :tabindex="scope.tabindex"
                    color="secondary"
                    outline
                    removable
                    @remove="scope.removeAtIndex(scope.index)"
                  >
                    {{ scope.opt.name }}
                  </q-chip>
                </template>

                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Нет данных
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>

              <ValidationProvider
                v-slot="{ errors }"
                name="Правообладатель"
                rules="min:2|max:255"
              >
                <q-input
                  v-model="project.rightholder"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  clearable
                  label="Правообладатель"
                />
              </ValidationProvider>

              <q-input
                v-model="project.sales_start"
                bottom-slots
                clearable
                filled
                label="Старт продаж"
                @click="$refs.datePickerSalesStart.show()"
              >
                <template v-slot:append>
                  <q-icon class="cursor-pointer" name="mdi-calendar">
                    <q-popup-proxy ref="datePickerSalesStart">
                      <q-date
                        v-model="project.sales_start"
                        mask="YYYY-MM-DD"
                        @mouseleave="$refs.datePickerSalesStart.hide()"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>

              <q-select
                v-model="project.development_stage"
                :options="lists.developmentStages"
                bottom-slots
                clearable
                label="Стадия проработки"
                map-options
                option-label="name"
                option-value="id"
                options-dense
              />

              <ValidationProvider
                v-slot="{ errors }"
                name="Заметка к стадии проработки"
                rules="max:500"
              >
                <q-input
                  v-model="project.development_stage_note"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  filled
                  label="Заметка к стадии проработки"
                  outline
                  rows="3"
                  type="textarea"
                />
              </ValidationProvider>
            </fieldset>
          </form>
        </div>
      </div>

      <div class="col-24 col-md-8">
        <div
          class="l-card l-card--green rounded-borders shadow-2 q-pa-md bg-white q-mb-md"
        >
          <form
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
          >
            <fieldset
              :disabled="awaitSaving"
              class="no-border q-pa-none q-ma-none"
            >
              <q-select
                v-model="project.estate_classes"
                :options="lists.estateClasses"
                filled
                label="Классы недвижимости"
                multiple
                option-label="name"
                option-value="id"
                options-dense
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    :tabindex="scope.tabindex"
                    color="secondary"
                    outline
                    removable
                    @remove="scope.removeAtIndex(scope.index)"
                  >
                    {{ scope.opt.name }}
                  </q-chip>
                </template>
              </q-select>

              <q-select
                v-model="project.estate_subject"
                :options="lists.estateSubjects"
                bottom-slots
                clearable
                label="Формат проекта"
                map-options
                option-label="name"
                option-value="id"
                options-dense
                @input="onInputEstateSubject"
              />

              <q-select
                v-model="project.estate_types"
                :options="estateTypes"
                filled
                label="Типы недвижимости"
                multiple
                option-label="name"
                option-value="id"
                options-dense
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    :tabindex="scope.tabindex"
                    color="secondary"
                    outline
                    removable
                    @remove="scope.removeAtIndex(scope.index)"
                  >
                    {{ scope.opt.name }}
                  </q-chip>
                </template>
              </q-select>
            </fieldset>
          </form>
        </div>

        <div
          class="l-card l-card--amber rounded-borders shadow-2 q-pa-md bg-white"
        >
          <form
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
          >
            <fieldset
              :disabled="awaitSaving"
              class="no-border q-pa-none q-ma-none"
            >
              <q-select
                v-model="project.source_infos"
                :options="lists.sourceInfos"
                bottom-slots
                filled
                label="Источники информации"
                multiple
                option-label="name"
                option-value="id"
                options-dense
                use-chips
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    :tabindex="scope.tabindex"
                    color="secondary"
                    outline
                    removable
                    @remove="scope.removeAtIndex(scope.index)"
                  >
                    {{ scope.opt.name }}
                  </q-chip>
                </template>
              </q-select>

              <ValidationProvider
                v-slot="{ errors }"
                name="Уточнение источников"
                rules="max:500"
              >
                <q-input
                  v-model="project.source_detail"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  filled
                  label="Уточнение источников"
                  max-length="500"
                  outline
                  rows="2"
                  type="textarea"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Примечание к источникам"
                rules="max:500"
              >
                <q-input
                  v-model="project.source_note"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  filled
                  label="Примечание к источникам"
                  max-length="500"
                  outline
                  rows="2"
                  type="textarea"
                />
              </ValidationProvider>

              <div class="row">
                <div class="col-24">
                  <span class="q-mr-md">Ссылки</span>
                  <q-btn
                    v-if="+$can(['project.update'])"
                    icon="mdi-plus"
                    round
                    @click="addLink"
                  />
                </div>

                <div v-for="(val, i) in project.links" class="col-24">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      max: 2000,
                      regex:
                        /^(?:http(s)?:\/\/)?[\wа-яА-Я.-]+(?:\.[\wа-яА-Я\.-]+)+[\wа-яА-Я\-\._«»„”~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                    }"
                    name="Ссылка"
                  >
                    <q-input
                      v-model="project.links[i]"
                      :error="errors && !!errors.length"
                      :error-message="errors[0]"
                      bottom-slots
                      hide-bottom-space
                      label="Ссылка"
                      @input="(val) => (project.links[i] = decodeURI(val))"
                    >
                      <template v-slot:after>
                        <q-btn
                          dense
                          flat
                          icon="mdi-delete"
                          @click="project.links.splice(i, 1)"
                        />
                      </template>
                    </q-input>
                  </ValidationProvider>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <div class="col-24 col-md-8">
        <div
          class="l-card l-card--brown rounded-borders shadow-2 bg-white q-pa-md q-mb-md"
        >
          <form
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
          >
            <fieldset
              :disabled="awaitSaving"
              class="no-border q-pa-none q-ma-none"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Инфраструктура"
                rules="max:1000"
              >
                <q-input
                  v-model="project.infrastructure"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  filled
                  label="Инфраструктура"
                  max-length="1000"
                  outline
                  rows="2"
                  type="textarea"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Заметка для bnMAP"
                rules="max:1000"
              >
                <q-input
                  v-model="project.bnmap_note"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  filled
                  label="Заметка для bnMAP"
                  max-length="1000"
                  outline
                  rows="2"
                  type="textarea"
                />
              </ValidationProvider>
            </fieldset>
          </form>
        </div>

        <div
          class="l-card l-card--deep-orange rounded-borders shadow-2 bg-white"
        >
          <q-list style="max-height: 400px; overflow-y: auto">
            <q-item-label header>Комментарии</q-item-label>
            <div v-if="comments">
              <Comment
                v-for="item in comments"
                :key="item.id"
                :comment="item"
                :entityId="project.id"
                :insetLevel="0"
                entity="project"
              />
            </div>

            <div v-if="!comments" class="text-grey-6 q-ml-md">Нет данных</div>
          </q-list>

          <q-separator />

          <div class="q-pa-md">
            <WriteComment
              v-if="+$can(['comment.add'])"
              :entityId="project.id"
              entity="project"
            />
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import Comment from "@/components/comment/Comment";
  import WriteComment from "@/components/comment/WriteComment";
  import ProjectHeader from "@/components/projects/ProjectHeader";

  const ESTATE_TYPES = {
    0: "kvartiry-pod-renovaciyu",
    1: "gostinica",
    2: "riteyl",
    3: "ofisy",
    4: "apartamenty",
    5: "kvartiry",
  };

  const ESTATE_SUBJECT_HAS_ESTATE_TYPES = {
    "zhiloy-kompleks": [ESTATE_TYPES[0], ESTATE_TYPES[2], ESTATE_TYPES[5]],
    "apart-kompleks": [
      ESTATE_TYPES[1],
      ESTATE_TYPES[2],
      ESTATE_TYPES[3],
      ESTATE_TYPES[4],
    ],
    mfk: [...Object.values(ESTATE_TYPES)],
    kot: [...Object.values(ESTATE_TYPES)],
  };

  export default {
    name: "ProjectCommon",

    components: {
      Comment,
      WriteComment,
      ProjectHeader,
    },

    props: {
      project: {
        type: Object,
        required: true,
      },
      lists: {
        type: Object,
        required: true,
      },
      viewers: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        awaitSaving: false,
        awaitApProjects: false,
        apProjects: [],
        estateTypes: [],
        developers: [],
        comments: null,
      };
    },

    created() {
      this.$root.$on("comment-added", this.onCommentAdded);
      this.$root.$on("comment-deleted", this.onCommentDeleted);
      this.$root.$on("comment-updated", this.onCommentUpdated);

      this.apProjectsData = []; // non-reactive

      this.setComments();
      this.setApProjects();

      // clone for filtering
      this.estateTypes = this.lists.estateTypes;
      this.developers = this.lists.developers;

      this.filterEstateTypesByEstateSubjectAlias(
        this.project.estate_subject && this.project.estate_subject.alias
      );
    },

    destroyed() {
      this.$root.$off("comment-added", this.onCommentAdded);
      this.$root.$off("comment-deleted", this.onCommentDeleted);
      this.$root.$off("comment-updated", this.onCommentUpdated);
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitSaving = true;

            let data = {
              name: this.project.name,
              project_status_id:
                this.project.project_status && this.project.project_status.id,
              biz_region_id: this.project.biz_region.id,
              privacy_level_id:
                this.project.privacy_level && this.project.privacy_level.id,
              developer_ids:
                this.project.developers &&
                this.project.developers.map((item) => item.id),
              rightholder: this.project.rightholder,
              development_stage_id:
                this.project.development_stage &&
                this.project.development_stage.id,
              development_stage_note: this.project.development_stage_note,
              infrastructure: this.project.infrastructure,
              estate_class_ids:
                this.project.estate_classes &&
                this.project.estate_classes.map((item) => item.id),
              estate_subject_id:
                this.project.estate_subject && this.project.estate_subject.id,
              estate_type_ids:
                this.project.estate_types &&
                this.project.estate_types.map((item) => item.id),
              source_info_ids:
                this.project.source_infos &&
                this.project.source_infos.map((item) => item.id),
              source_detail: this.project.source_detail,
              source_note: this.project.source_note,
              links: this.project.links,
              sales_start: this.project.sales_start,
              ap_project_serial: this.project.ap_project_serial,
              project_kind_id:
                this.project.project_kind && this.project.project_kind.id,
              bnmap_note: this.project.bnmap_note,
            };

            this.$api.project
              .updateCommon(this.project.id, data)
              .then(
                (res) => {
                  if (res.status === 200) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitSaving = false;
              });
          }
        });
      },

      filterApProjects(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.apProjects = this.apProjectsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterDevelopers(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.developers = this.lists.developers.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterEstateTypesByEstateSubjectAlias(alias) {
        if (!alias) {
          return;
        }

        this.estateTypes = this.lists.estateTypes.filter((item) => {
          return ESTATE_SUBJECT_HAS_ESTATE_TYPES[alias].includes(item.alias);
        });
      },

      onCommentDeleted(commentId) {
        this.setComments();
      },

      onCommentAdded(payload) {
        this.setComments();
      },

      onCommentUpdated(payload) {
        this.setComments();
      },

      onInputEstateSubject(val) {
        if (!val) {
          return;
        }

        this.filterEstateTypesByEstateSubjectAlias(val.alias);
      },

      addLink() {
        if (!this.project.links) {
          this.project.links = [];
        }

        this.project.links.push("");
      },

      async setApProjects() {
        // get ap projects only once
        if (this.apProjectsData.length) {
          return;
        }

        let options = {
          limit: 5000,
        };

        this.awaitApProjects = true;

        await this.$api.ap
          .findProjects(options)
          .then(
            (res) => {
              this.apProjects = res.data;
              this.apProjectsData = res.data;
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitApProjects = false;
          });
      },

      setComments() {
        return this.$api.project
          .findById(this.project.id, "comments")
          .then((res) => {
            let comments = res.data.comments
              ? res.data.comments.filter((item) => item.category === null)
              : null;

            if (comments && comments.length) {
              this.comments = comments;
            }
          });
      },
    },
  };
</script>